$white: #ffffff;
$black: #000000;

$yellow: #ECA900;
$yellow-light: #ECB018;
$yellow-lighter: #ECC45E;

$gray-light: #DDDDDD;
$gray-lighter: #EDEDED;
$gray-dark: #393939;

$title-font: 'Open Sans Condensed', sans-serif;
$body-font: 'Open Sans', sans-serif;

/* General */

body {
  background: $white;
  color: $black;
  font-family: $body-font;
}

h1, h2, h3{
  font-family: $title-font;
  letter-spacing: 0.06rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}

h2{
  font-size: 2.7rem;
}

h3{
  font-size: 2rem;
}

h4, p {
  font-family: $body-font;
}

h4{
  font-size: 16px;
}

ins {
  background: $yellow-light;
  color: $black;
}

.uk-form-label{
  font-family: $body-font;
}



.uk-nav-header {
  text-transform: uppercase;
  font-size: 1rem;
  color: $black;
}

.uk-nav > li > a {
    padding: 3px 0;
}

.uk-nav-header {
  padding: 0px 0;
  text-transform: uppercase;
  font-size: .875rem;
}

.uk-nav-header:not(:first-child) {
    margin-top: 10px;
}

.uk-nav-project{
  text-transform: uppercase;
  font-size: .875rem;
  color: $black;
}

a{
  color: $gray-dark;
}

a:hover {
  color: $yellow;
}

.uk-icon{
  color: $yellow;
}


.uk-button{
  border-radius: 3px;
  font-family: $body-font;
  text-transform: none;
  padding-left: 15px;
  padding-right: 15px;
}

.uk-button-default{
  background: $yellow;
  color: $black;
  border-color: $yellow;

}

.uk-button-default:hover{
  background: $yellow-lighter;
  color: $black;
  border-color: $yellow-lighter;
}

.uk-form-label{
  font-size: 16px;
  color: $black;
}

.uk-active{
  color: $yellow;
}

.a-uk-active{
  color: $yellow;
}

// Sections

#contact{
  background-image: url(../img/bg-contact.png);
}

#menu{
  background-image: url(../img/bg-menu.png);
}

// Home

.logo{
  height: 100px;
}

.logo-menu{
  height: 85px;
}

#carrousel{
  height: 665px;
  max-height: 665px;
}

#bg-logo{
  height: 650px;
}

.carrousel{
  height: 665px;
}

// Projects
.grayscale {
  filter: grayscale(100%);
}

.img-projects{
  height: 500px;
}
